
export default function(pages)
{
	let classes = document.body.className.replace(/-/g, '_').split(/\s+/);

	for (let key in pages)
	{
		if (classes.indexOf(key) != -1)
			pages[key]();
	}

	// Test for _global
	let globalFunc = pages["_global"];

	// Run _global function if it was found.
	if (typeof globalFunc == "function")
		globalFunc();
};
