
import SiteUtils from "../utils/site-utils";

let controller;

function fragmentFromString(html)
{
	return document.createRange().createContextualFragment(html);
}

function setupLazyLoad()
{
	const scene = new ScrollMagic.Scene(
	{
		triggerElement: ".SiteFooter",
		triggerHook: 1,
		offset: "-250"
	})
	.on("enter", evt =>
	{
		let lastPage = $(".ArchiveList:last-child"),
			nextPage = lastPage.getAttribute("data-next");

		if (!nextPage)
			return;

		lastPage.classList.add("is-loading");

		fetch(nextPage)
			.then(response =>
			{
				lastPage.classList.remove("is-loading");
				return response.text();
			})
			.then(body =>
			{
				let nextArchiveList = fragmentFromString(body);
				$(".SiteMain").appendChild(nextArchiveList);

				let addedElement = $(".SiteMain").querySelector(".ArchiveList:last-child");

				TweenMax.staggerFromTo(addedElement.children, 1,
				{
					y: 30,
					opacity: 0
				},
				{
					opacity: 1,
					delay: .2,
					y: 0,
					ease: Power4.easeOut,
					force3D: true
				}, 0.15);

			});

	})
	//.addIndicators({ name: "footer" })
	.addTo(controller);
}

function init()
{
	if(controller){
		controller = controller.destroy(true);
		controller = new ScrollMagic.Controller();
		//console.log("has controller");
	}else{
		controller = new ScrollMagic.Controller();
		//console.log("no controller");
	}
}

function destroy()
{

}

export default { init, destroy }
