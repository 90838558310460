let logoEl = document.querySelector(".SiteHeader-logo");

export default
{
	getLogoColor()
	{
		let logoFill = logoEl.style.fill;

		if (!logoFill || logoFill == "rgb(0, 0, 0)" || logoFill == "black")
			return "black";

		return "white";
	},

	setLogoColor(color, animate = true)
	{
		//console.log("setLogoColor", color, animate);

		if (animate === false)
			logoEl.style.transition = "none";

		logoEl.style.fill = color;

		window.requestAnimationFrame(() =>
		{
			logoEl.style.transition = "";
		});
	}
}
