// Go to dev: cd user/themes/gravstarter/assets-builder
"use strict";
import PageFunctions from "./utils/page-functions";
import FrontPage from "./pages/frontpage";
import SingleCasePage from "./pages/single-case";
import PostsPage from "./pages/posts";
import SharedPage from "./pages/shared-page";
import Barba from "barba.js";
//import fullpage from "./utils/fullpage.js";
import SiteUtils from "./utils/site-utils";
import PageScroll from "./utils/pageScroll.js";

// Preloader
let preload = document.getElementsByTagName("BODY")[0];
let w = window.innerWidth;
let h = window.innerHeight;


window.onload = function() {
	preload.setAttribute("data-spinner", "false");
};
function doFP(){
	var fpIsActive = document.querySelector("#fullpage");

	if(fpIsActive){
		if(w < 768){
			return true
		}else{
			fullpage.initialize('#fullpage', {
				'menu': false,
				'css3':true,
				'touchSensitivity': 0,
				'loopHorizontal': false,
			  'autoScrolling': true,
			  onLeave: function(index, nextIndex, direction){
					let section = document.querySelectorAll(".section");
			    //console.log(direction);
			    //console.log(nextIndex-1);
			    //console.log(index-1);
			    for (var i = 0; i < section.length; i++) {
			      section[i].setAttribute('data-state', "out");
			    }

			    section[nextIndex-1].setAttribute('data-direction', direction);
			    section[nextIndex-1].setAttribute('data-state', "enter");

			    section[index-1].setAttribute('data-direction', direction);
			    section[index-1].setAttribute('data-state', "leave");

					let fpLogo = document.querySelector(".SiteHeader-logo");
					fpLogo.style.fill = "black";
					if(nextIndex > 1 && nextIndex < 7){
						fpLogo.style.fill = "white";
					}else{
						fpLogo.style.fill = "black";
					}
				}
			})
		}

	}
}

window.requestAnimFrame = (function() {
	return window.requestAnimationFrame ||
		window.webkitRequestAnimationFrame ||
		window.mozRequestAnimationFrame ||
		function(callback) {
			window.setTimeout(callback, 1000 / 60);
		};
})();

const bigbody = document.body;
let logoClass = document.querySelector(".SiteHeader-logo");

var Homepage = Barba.BaseView.extend({
  namespace: 'front-page',
  onEnter: function() {
		logoClass.style.fill = "black";
		bigbody.setAttribute('class', this.namespace);
  },
  onEnterCompleted: function() {
		SharedPage.init();
		history.scrollRestoration = 'manual';
		window.scrollTo(0, 0);
		//doFP();
		if(w > 960){
			PageScroll.init();
		}
  },
  onLeave: function() {
		if(w > 960){
			PageScroll.destroy();
		}
		SharedPage.destroy();
  },
  onLeaveCompleted: function() {

  }
});
var Clients = Barba.BaseView.extend({
  namespace: 'tmpl-clients',
  onEnter: function() {
		logoClass.style.fill = "black";
		bigbody.setAttribute('class', this.namespace);

  },
  onEnterCompleted: function(e) {
		history.scrollRestoration = 'manual';
		window.scrollTo(0, 0);
		SiteUtils.setLogoColor("black");
		SharedPage.init();
  },
  onLeave: function() {
		SharedPage.destroy();
  },
  onLeaveCompleted: function() {
  }
});
var News = Barba.BaseView.extend({
  namespace: 'posts-page',
  onEnter: function() {
		bigbody.setAttribute('class', this.namespace);
  },
  onEnterCompleted: function(e) {
		history.scrollRestoration = 'manual';
		window.scrollTo(0, 0);
		SiteUtils.setLogoColor("black");
		SharedPage.init();
  },
  onLeave: function() {
		SharedPage.destroy();
  },
  onLeaveCompleted: function() {

  }
});
var NewsSingle = Barba.BaseView.extend({
  namespace: 'single-post',
  onEnter: function() {
		bigbody.setAttribute('class', this.namespace);
  },
  onEnterCompleted: function(e) {
		history.scrollRestoration = 'manual';
		window.scrollTo(0, 0);
		SharedPage.init();
		SharedPage.setupLogoChanger();
  },
  onLeave: function() {

  },
  onLeaveCompleted: function() {
      // The Container has just been removed from the DOM.
      SharedPage.destroy();
			SharedPage.destroyLogoChanger();
  }
});
var About = Barba.BaseView.extend({
  namespace: 'tmpl-about',
  onEnter: function() {

		bigbody.setAttribute('class', this.namespace);

  },
  onEnterCompleted: function(e) {
		history.scrollRestoration = 'manual';
		window.scrollTo(0, 0);
		SharedPage.init();
		SiteUtils.setLogoColor("black");
		let el = document.querySelectorAll('.Profil-grid__content');
		let rest = document.querySelectorAll('.Profil-grid__content').length % 4;
		for (var i=el.length-rest; i<el.length; i++) {
		    console.log(i);
		    if(rest==2){
		      el[i].classList.add("rest-two");
		    }
		    if(rest==3){
		      el[i].classList.add("rest-three");
		    }
		}
  },
  onLeave: function() {
		SharedPage.destroy();
  },
  onLeaveCompleted: function() {

  }
});
var Case = Barba.BaseView.extend({
  namespace: 'single-case',
  onEnter: function() {

		bigbody.setAttribute('class', this.namespace);
  },
  onEnterCompleted: function(e) {
		history.scrollRestoration = 'manual';
		window.scrollTo(0, 0);
		SingleCasePage.beforeInit();
		SharedPage.init();
		SharedPage.setupLogoChanger();
  },
  onLeave: function() {
      // A new Transition toward a new page has just started.
			bigbody.setAttribute('style', '');
			SingleCasePage.destroy();
			SharedPage.destroy();
			SharedPage.destroyLogoChanger();
  },
  onLeaveCompleted: function() {

  }
});


function initGmaps() {
		var mapOptions = {
		zoom: 15,

		// Latitude and longitude to center the map (Required)
		center: new google.maps.LatLng(55.699439, 12.581974),

				// Styles from Snazzy Maps
		styles: [{"featureType":"all","elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"featureType":"all","elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"featureType":"all","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"water","elementType":"geometry.fill","stylers":[{"color":"#CBDCE1"}]},{},{},{}]
		};
		var mapElement = document.getElementById('map');
		var map = new google.maps.Map(mapElement, mapOptions);

			// Add the marker
			var customMarker = './user/themes/gravstarter/images/think-pin.svg';
			var marker = new google.maps.Marker({
			position: new google.maps.LatLng(55.699439, 12.581974),
			icon: customMarker,
			map: map,
			title: 'THINK'
		});
}

var Contact = Barba.BaseView.extend({
  namespace: 'tmpl-contact',
  onEnter: function() {
		bigbody.setAttribute('class', this.namespace);
  },
  onEnterCompleted: function(e) {
		history.scrollRestoration = 'manual';
		window.scrollTo(0, 0);
		SharedPage.init();
		google.maps.event.addDomListener(window, 'load', initGmaps);
		initGmaps();
		SiteUtils.setLogoColor("black");
  },
  onLeave: function() {
      // A new Transition toward a new page has just started.
			bigbody.setAttribute('style', '');
  },
  onLeaveCompleted: function() {
      // The Container has just been removed from the DOM.

			SharedPage.destroy();
  }
});

var Four0four = Barba.BaseView.extend({
  namespace: 'four0four',
  onEnter: function() {

		bigbody.setAttribute('class', this.namespace);

  },
  onEnterCompleted: function(e) {
		history.scrollRestoration = 'manual';
		window.scrollTo(0, 0);
		SharedPage.init();
		SiteUtils.setLogoColor("black");
  },
  onLeave: function() {
		SharedPage.destroy();
  },
  onLeaveCompleted: function() {

  }
});

function urlActive(){
  let navLinks = document.querySelectorAll(".SiteNav-links a");
  let current = location.pathname.split("/");
  //console.log(current[current.length-1]);
  for (var i = 0; i < navLinks.length; i++) {
    let active = navLinks[i].getAttribute('href').split("/");
    navLinks[i].parentNode.classList.remove("is-active")

    if(active[active.length-1] == current[current.length-1]){
        navLinks[i].parentNode.classList.add('is-active');
    }
  }
}

//--Pagetransition ------------
var XTranslate = Barba.BaseTransition.extend({
  start: function() {
    // As soon the loading is finished and the old page is faded out, let's fade the new page
    this.oldContainer.style.visibility = 'hidden';
		preload.setAttribute("data-spinner", "true");
    Promise
      .all([this.newContainerLoading])
      .then(this.fadeIn.bind(this));
  },
  fadeIn: function() {
		var _this = this;
		_this.newContainer.style.visibility = 'visible';
		_this.newContainer.setAttribute("data-state", "entered");
		_this.oldContainer.setAttribute("data-state", "leaved");
		setTimeout(function(){
			_this.newContainer.setAttribute("data-state", "done");
			urlActive()
	    _this.done();
			preload.setAttribute("data-spinner", "false");
		}, 300)
  }
});
/**
 * Next step, you have to tell Barba to use the new Transition
 */
Barba.Pjax.getTransition = function() {
  /**
   * Here you can use your own logic!
   * For example you can use different Transition based on the current page or link...
   */
  return XTranslate;

};


Homepage.init();
Clients.init();
Case.init();
News.init();
NewsSingle.init();
About.init();
Contact.init();
Four0four.init();
Barba.Prefetch.init();
Barba.Pjax.start();

Barba.Dispatcher.on('newPageReady', function() {
	window.dataLayer = window.dataLayer || [];
	 window.dataLayer.push({
	 'event': 'pageChange'
	 });
});
