
import { mediaMatcher, getElementHeight } from "../utils/utils";

let controller;
const casesEl = $(".Cases");

function init()
{
	if(controller){
		controller = controller.destroy(true);
		controller = new ScrollMagic.Controller();
		setupIntroScene();
		$$(".CaseSpot").forEach(setupCaseSpotScene);
		setupIntroOutScene();
		//console.log("has controller");
	}else{
		controller = new ScrollMagic.Controller();
		setupIntroScene();
		$$(".CaseSpot").forEach(setupCaseSpotScene);
		setupIntroOutScene();
		//console.log("no controller");
	}
}

function setupIntroScene()
{
	const heroScene = new ScrollMagic.Scene(
	{
		triggerElement: document.body,
		duration: "100%",
		triggerHook: 1
	})
	//.addIndicators({ name: "frontpage" })

	.addTo(controller);

	let intro = document.querySelector(".Intro"),
		section = document.querySelector(".Intro > section"),
		padding = parseInt(window.getComputedStyle(intro, null).getPropertyValue("padding-top"));

	let height = section.clientHeight + (padding * 2);

	let tweens =
	[
		TweenMax.to(".frontpage-image-fade", .3,
		{
			opacity: 0,
			force3D: true
		}),

		TweenMax.to(".Intro", .3,
		{
			y: 120,
			height: height,
			ease: Power1.easeOut,
			force3D: true
		})

	];

	tweens.push(
		TweenMax.to(".SiteHeader-logo svg", .5,
		{
			fill: "#fff",
			ease: Power1.easeInOut,
			offset: 1,
			force3D: true
		})
	);


	let timeline = new TimelineMax();
	timeline.add(tweens);

	heroScene.setTween(timeline);

}

function setupIntroOutScene()
{
	let introEl = $(".Intro");

	const scene = new ScrollMagic.Scene(
	{
		triggerElement: casesEl,
		duration: getElementHeight.bind(null, casesEl),
		triggerHook: .8,
		force3D: true
	})
	.on("enter", evt =>
	{
		TweenMax.to(introEl, .3,
		{
			x: "0%",
			opacity: 1,
			ease: Power4.easeOut,
		});



	})
	.on("leave", evt =>
	{
		if (evt.state != "AFTER")
			return;

		TweenMax.to(introEl, .3,
		{
			x: "-100%",
			opacity: 0,
			fill: "#000",
			ease: Power1.easeIn
		});
	})
	//.addIndicators({ name: "cases" })
	.addTo(controller);
}

function setupCaseSpotScene(caseSpotEl, index)
{
	if (index == 0)
		document.body.style.backgroundColor = caseSpotEl.getAttribute("data-bg");

	let link = caseSpotEl.querySelector(".CaseSpot-link");

	let scene1 = new ScrollMagic.Scene(
	{
		triggerElement: link,
		duration: 0,
		offset: -(getElementHeight(caseSpotEl) / 2.5),
		triggerHook: .5,
		reverse: false,
		force3D: true
	})
	.on("enter", evt =>
	{
		let triggerElement = evt.target.triggerElement();

		let imgAnimation = triggerElement.parentNode.querySelector(".CaseSpot-img");
		TweenMax.to(imgAnimation, 1,
		{
			x: "0",
			opacity: 1,
			ease: Power4.easeOut
		});

		TweenMax.staggerFromTo(triggerElement.children, 1,
		{
			y: 80
		},
		{
			opacity: 1,
			delay: .2,
			y: 0,
			ease: Power4.easeOut,
			force3D: true
		}, 0.15);

	})
	//.addIndicators({ name: "caseLink " + index })
	.addTo(controller);

	new ScrollMagic.Scene(
	{
		triggerElement: caseSpotEl,
		duration: getElementHeight.bind(null, caseSpotEl),
		triggerHook: .5
	})
	.on("enter", evt =>
	{
		if (evt.state != "DURING")
			return;

		let scene = evt.target,
			triggerElement = evt.target.triggerElement(),
			bgColor = triggerElement.getAttribute("data-bg");

		TweenMax.to(document.body, .5,
		{
			backgroundColor: bgColor
			// ease: Power1.easeOut
		});
	})
	// .addIndicators({ name: "case " + index })
	.addTo(controller);
}

function destroy()
{
	controller = controller.destroy();
}

export default { init, destroy }
