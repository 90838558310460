// VARIABLES
var ticking;
var isFirefox;
var isIe;
var scrollSensitivitySetting;
var slideDurationSetting;
var currentSlideNumber;
var slideElements;
var totalSlideNumber;
var delta;

// Throttle
function throttle(func, wait, options) {
  var context, args, result;
  var timeout = null;
  var previous = 0;
  if (!options) options = {};
  var later = function() {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };
  return function() {
    var now = Date.now();
    if (!previous && options.leading === false) previous = now;
    var remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
};

// DIRECTION
function wheelScroll(evt) {
  if (isFirefox) {
    //Set delta for Firefox
    delta = evt.detail * (-120);
  } else if (isIe) {
    //Set delta for IE
    delta = -evt.deltaY;
  } else {
    //Set delta for all other browsers
    delta = evt.wheelDelta;
  }
  if (ticking != true) {
    if (delta <= -scrollSensitivitySetting) {
      //Down scroll
      ticking = true;
      if (currentSlideNumber !== totalSlideNumber - 1) {
        currentSlideNumber++;
        nextItem();
      }
      slideDurationTimeout(slideDurationSetting);
    }
    if (delta >= scrollSensitivitySetting) {
      //Up scroll
      ticking = true;
      if (currentSlideNumber !== 0) {
        currentSlideNumber--;
      }
      previousItem();
      slideDurationTimeout(slideDurationSetting);
    }
  }
}

function touchScroll(ts, te) {
  delta = te - ts;
  console.log('para');
  if (ticking != true) {
    if (delta <= -scrollSensitivitySetting) {
      //Down scroll
      ticking = true;
      if (currentSlideNumber !== totalSlideNumber - 1) {
        currentSlideNumber++;
        nextItem();
      }
      slideDurationTimeout(slideDurationSetting);
    }
    if (delta >= scrollSensitivitySetting) {
      //Up scroll
      ticking = true;
      if (currentSlideNumber !== 0) {
        currentSlideNumber--;
      }
      previousItem();
      slideDurationTimeout(slideDurationSetting);
    }
  }
}

// Lock slides
function slideDurationTimeout(slideDuration) {
  setTimeout(function() {
    ticking = false;
  }, slideDuration);
}



// ------------- SLIDE MOTION ------------- //
function nextItem() {
  var previousSlide = slideElements[currentSlideNumber - 1];
  previousSlide.classList.remove("up-scroll");
  previousSlide.classList.add("down-scroll");
  let fpLogo = document.querySelector(".SiteHeader-logo");
  fpLogo.style.fill = "black";
  if(currentSlideNumber - 1 > 0){
    fpLogo.style.fill = "white";
  }else{
    fpLogo.style.fill = "black";
  }
}

function previousItem() {
  var currentSlide = slideElements[currentSlideNumber];
  currentSlide.classList.remove("down-scroll");
  currentSlide.classList.add("up-scroll");
  let fpLogo = document.querySelector(".SiteHeader-logo");
  fpLogo.style.fill = "black";
  if(currentSlideNumber - 1 > 0){
    fpLogo.style.fill = "white";
  }else{
    fpLogo.style.fill = "black";
  }
}

// ADD EVENT LISTENER
var mousewheelEvent;
var ts;

function init(){
  ticking = false;
  isFirefox = (/Firefox/i.test(navigator.userAgent));
  isIe = (/MSIE/i.test(navigator.userAgent)) || (/Trident.*rv\:11\./i.test(navigator.userAgent));
  scrollSensitivitySetting = 30; //Increase/decrease this number to change sensitivity to trackpad gestures (up = less sensitive; down = more sensitive)
  slideDurationSetting = 1000; //Amount of time for which slide is "locked"
  currentSlideNumber = 0;
  slideElements = document.querySelectorAll(".background")
  totalSlideNumber = slideElements.length;
  delta;
  mousewheelEvent = isFirefox ? "DOMMouseScroll" : "wheel";
  window.addEventListener(mousewheelEvent, throttle(wheelScroll, 60), false);
  window.addEventListener("touchstart", function(e) {
    ts = e.touches[0].clientY;
  }, false);
  window.addEventListener("touchend", function(e) {
    var te = e.changedTouches[0].clientY;
    touchScroll(ts, te);
  }, false);
}

function destroy(){
  window.removeEventListener(mousewheelEvent, throttle(wheelScroll, 60), false);
  window.removeEventListener("touchstart", function(e) {
    ts = e.touches[0].clientY;
  }, false);
  window.removeEventListener("touchend", function(e) {
    var te = e.changedTouches[0].clientY;
    touchScroll(ts, te);
  }, false);
}





export default {
  destroy,
  init
}
