
export function getDocumentHeight()
{
	return document.body.clientHeight;
}

export function getElementHeight(el)
{
	return el.clientHeight;
}

export function mediaMatcher(
	{
		query,
		inside = () => {},
		outside = () => {}
	})
{
	// if the browser does not support matchMedia,
	// just call the outside() function.
	if (!window.matchMedia)
	{
		outside(null, true);
		return;
	}

	let mediaQuery = window.matchMedia(query),
		callback = (mq, initial) =>
		{
			mediaQuery.matches ?
				inside(mediaQuery, !!initial) :
				outside(mediaQuery, !!initial)
		};

	mediaQuery.addListener(callback);

	callback(mediaQuery, true);

	return mediaQuery;
}
