
import { getElementHeight } from "../utils/utils";
import SiteUtils from "../utils/site-utils";


let caseNavProgressEl
let anchorLinks
let siteMainEl

let controller;

let anchorScences = [];

function beforeInit(){
	if(controller){
		controller = controller.destroy(true);
		controller = new ScrollMagic.Controller();
		init()
	}else{
		controller = new ScrollMagic.Controller();
		init()
	}
}
function init()
{
	caseNavProgressEl = $(".CaseNav-progress");
	anchorLinks = $$(".CaseNav-anchors-link");
	siteMainEl = $(".SiteMain");
	window.addEventListener("load", SiteUtils.setupLogoChanger);
	controller.scrollTo(scrollY =>
	{
		anchorScences.forEach(s => s.enabled(false));

		TweenMax.to(window, .5,
		{
			scrollTo: { y: scrollY },
			ease: Expo.easeOut,
			onComplete: () =>
			{
				anchorScences.forEach(s => s.enabled(true));
			}
		});
	});

	setupCaseBgFader();
	setupAnchorLinks();
	setupSliders();
	setupProgressTracker();
	if( document.querySelector(".VideoModule-video") ){
		setupVideoPlayer();
	}

}

function destroy()
{
	controller = controller.destroy();
	window.removeEventListener("load", SiteUtils.setupLogoChanger);
}


function setupCaseBgFader()
{
	$$(".CaseSection").forEach(el =>
	{
		let scene = new ScrollMagic.Scene(
			{
				triggerElement: el,
				duration: "100%",
				triggerHook: .5
			})
			.on("enter", function (evt)
			{
				if (evt.state != "DURING")
					return;

				let scene = evt.target,
					element = evt.target.triggerElement(),
					bgColor = element.getAttribute("data-bg");

				TweenMax.to(document.body, .5,
				{
					backgroundColor: bgColor,
					// ease: Power1.easeOut
				});

				if(bgColor == "#000000" || bgColor == "#D91E50" || bgColor == "#2A3880" ||
				bgColor == "#262626" || bgColor == "#1f419b" || bgColor == "#565759" ||
				bgColor == "#72b1d8" || bgColor == "#2c3e50"){

					SiteUtils.setLogoColor("white");
				}else{
					SiteUtils.setLogoColor("black");
				}


			})
			//.addIndicators({ name: "section" })
			.addTo(controller);
	});
}

function setupAnchorLinks()
{
	anchorLinks.forEach(link =>
	{
		let id = link.getAttribute("href").replace("/", "\\\/"),
			anchorTarget = document.querySelector(id);

		link.x_anchor_target_element = anchorTarget;
		anchorTarget.x_link = link;

		let scene = new ScrollMagic.Scene(
		{
			triggerElement: anchorTarget,
			duration: getElementHeight.bind(null, anchorTarget),
			triggerHook: .5
		})
		.on("enter", (evt) =>
		{
			if (evt.state != "DURING")
				return;

			// console.log("ENTER", evt.target.triggerElement().getAttribute("id"));

			let link = evt.target.triggerElement().x_link;
			anchorLinks.forEach(el => el.classList.remove("is-active"));
			link.classList.add("is-active");
		})
		// .addIndicators({ name: "section" })
		.addTo(controller);

		anchorScences.push(scene);
	});

	anchorLinks._.addEventListener("click", (evt) =>
	{
		evt.preventDefault();

		controller.scrollTo(evt.target.x_anchor_target_element);

		anchorLinks.forEach(el => el.classList.remove("is-active"));
		evt.target.classList.add("is-active");
	});
}

function setupProgressTracker()
{
	const progressScene = new ScrollMagic.Scene(
		{
			triggerElement: ".Page",
			duration: getElementHeight.bind(null, siteMainEl),
			triggerHook: "onLeave"
		})
		.on("progress", function (evt)
		{
			let progress = evt.progress;

			caseNavProgressEl.style.width = (progress * 135) + "%";

			// window.requestAnimationFrame(() => {});
		})
		// .addIndicators({ name: "progress" })
		.addTo(controller);
}

function setupSliders()
{
	const sliderScene = new ScrollMagic.Scene(
	{
		triggerElement: ".SliderModule",
		duration: "100%",
		triggerHook: .9
	})
	.on("enter", function (evt)
	{
		let scene = evt.target,
			sliderElement = evt.target.triggerElement(),
			sliderWrapper = sliderElement.querySelector(".swiper-wrapper");

		TweenMax.to(sliderWrapper, 1,
		{
			x: 0,
			opacity: 1,
			ease: Power1.easeOut,

			onComplete: () =>
			{
				scene.destroy();
			}
		});

	})
	//.addIndicators({ name: "slider" })
	.addTo(controller);

	$$(".SliderModule.is-large").forEach(el =>
	{
		let swiper = new Swiper(el.querySelector(".swiper-container"),
		{
			//freeMode: false,
			// prevButton: null,
			// nextButton: null,
			// grabCursor: true,
			speed: 600,
			spaceBetween: 20,
			// mousewheelControl: true,
			// mousewheelForceToAxis: true,
			// mousewheelSensitivity: .2,
			scrollbarDraggable: true,
			scrollbarSnapOnRelease: true,
			// slidesPerView: 4,
			buttonDisabledClass: "is-disabled",
			onInit: swiper =>
			{
				// Offset the slider, so we can animate it.
				swiper.wrapper[0].style.transform = "translate3d(150px, 0px, 0px)";
			}
		});
	});

	$$(".SliderModule.is-small").forEach(el =>
	{
		let swiper = new Swiper(el.querySelector(".swiper-container"),
		{
			slidesPerView: 3,
			speed: 600,
			centeredSlides: false,
			paginationClickable: true,
			spaceBetween: 20,
			freeMode: true,
			breakpoints:
			{
				1465: { slidesPerView: 2, spaceBetween: 10 }
			},
			onInit: swiper =>
			{
				swiper.prependSlide('<div class="swiper-slide is-spacer"></div>');
				// swiper.appendSlide('<div class="swiper-slide is-spacer"></div>');
				swiper.slideTo(0);
				swiper.update(true);
			}
		});
	});
}

function setupVideoPlayer()
{
	const videoScene = new ScrollMagic.Scene(
	{
		triggerElement: ".VideoModule",
		triggerHook: 1
	})
	.on("enter", function (evt)
	{
		let scene = evt.target,
			moduleElement = evt.target.triggerElement(),
			videoEl = moduleElement.querySelector(".VideoModule-video");

		  videoEl.play();
	})
	//.addIndicators({ name: "video" })
	.addTo(controller);
}

export default { beforeInit, init, destroy }
